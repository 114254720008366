
import Repository from "./Repository";
import store from '../store'

let locale = store.state.locale;

const resourse = "?locale=" + locale;
const about =  "about?locale=" + locale;
const contact =  "contact?locale=" + locale;
const methylamine =  "methylamine?locale=" + locale;
const products = "products?locale=" + locale;
const productsDetail = 'products/'


export default {
    get() {
        return Repository.get(`${resourse}`)
    },
    getAbout() {
        return Repository.get(`${about}`)
    },
    getContact() {
        return Repository.get(`${contact}`)
    },
    getMethylamine() {
        return Repository.get(`${methylamine}`)
    },
    getProducts() {
        return Repository.get(`${products}`)
    },
    getProductsItem(item) {
        return Repository.get(`${productsDetail + item + '?locale=' + locale}`)
    },

    // getPost() {
    //
    // },
    //
    // createPost() {
    //
    // },
}

