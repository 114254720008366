<template>
    <div class="locale-changer">
        <label>
            <select :required="true" v-model="langDefault">
                <option v-for="language in lang" :key="language.locale" :value="language.value">
                    {{ language.locale }}
                </option>
            </select>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'locale-changer',
        data() {
            return {
                langDefault: localStorage.getItem("locale") || 'bgr',
                lang: [{
                        locale: 'En',
                        value: 'en'
                    },
                    {
                        locale: 'Bgr',
                        value: 'bgr'
                    },
                    {
                        locale: 'Ru',
                        value: 'ru'
                    },
                ],
                locale: localStorage.getItem("locale"),
                location: window.location.toString()
            }
        },

        watch: {
            langDefault(newValue) {
                this.$store.dispatch('SET_LANGUAGE', localStorage.getItem("locale"));
                window.location = this.location.replace(window.localStorage.getItem('locale'), newValue);
                localStorage.setItem('locale', newValue);
            }
        },

        mounted() {
            localStorage.setItem('locale', this.langDefault);
            this.$store.dispatch('SET_LANGUAGE', localStorage.getItem("locale"))
        },


    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/index";

    .locale-changer {
        & select {
            background-color: transparent !important;
            border: none;
            color: $routerLink;
            font-weight: bold;

            & option {
                background-color: transparent !important;
                border: none;
                color: $base-color;
                font-size: 15px;
                font-weight: bold !important;
                padding: 14px !important;

                @media (max-width: 1200px) {
                    font-size: $fontSizeTables;
                }
            }
        }
    }
</style>