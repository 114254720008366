<template>
    <div class="layouts">

        <navbar />
        <main class="app_content">
            <slot />
        </main>
        <footers />
    </div>
</template>


<style lang="scss">
    @import '../assets/scss/index';

    .layouts {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        font-family: $font;

    }
</style>
<script>
    import navbar from "../components/app/navbar";
    import footers from "../components/app/footers";
    export default {
        components: {
            navbar,
            footers
        }
    }
</script>